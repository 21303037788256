@tailwind base;
@tailwind components;
@tailwind utilities;

/*Variables*/
:root {
    --site-width: 1280px;
    --primary-font: 'Nunito Sans', sans-serif;
    --heading-font: 'Maven Pro', sans-serif;
    --primary-color: #222;
    --heading-color: #000;
    --link-hover: #0018C9;
    --header-color: #343d43;
    --gray-text: #4c4c4c;
}

/*General*/
* {
    box-sizing: border-box;
  }
  
html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--primary-font);
    font-size: 14px;
    line-height: 1.7 !important;
    font-weight: 400;
    color: var(--primary-color);
    background: #fff;
}

/*Dark*/
.dark body {
    background: #1c1c1c;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font);
    font-weight: 700 !important;
    color: var(--heading-color);
}

a:hover {
    color: var(--link-hover);
}

.center {
    text-align: center;
}

.relative {
    position: relative;
}

.site-container {
    max-width: var(--site-width);
    margin: 0 auto;
    padding: 0 10px;
}

/*Header*/
.header {
    .header-current-matches {
        background: #50a794;
        padding: 20px 0;
        /*Skeleton*/
        .current-match-skeleton {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            .wrap {
                background: #fff;
                padding: 10px;
                .teams {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 10px;
                }
            }
        }
    }
    .main-navigation {
        background: var(--header-color);
        .nav-wrap {
            grid-gap: 40px;
            align-items: center;
            display: grid;
            grid-template-columns: auto 1fr auto;
            .nav {
                text-align: center;
                .nav-list {
                    display: flex;
                    justify-content: center;
                    .nav-item {
                        a {
                            display: block;
                            padding: 12px 7px;
                            &:hover {
                                background: #4690FF;
                            }
                            img {
                                height: 32px;
                                width: 32px;
                            }
                        }
                    }
                }
            }
            .site-color-switcher {
                display: flex;
                justify-content: right;
                gap: 10px;
                align-items: center;
                .login-btn {
                    background: #4690FF;
                    padding: 4px 13px;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 18px;
                    border-radius: 15px;
                    border: 2px solid #4690FF;
                    &:hover {
                        background: transparent;
                    }
                }
                .btn.theme-color-switcher {
                    background: hsla(0,0%,100%,.2);
                    padding: 7px 10px;
                    border-radius: 3px;
                    color: #fff;
                    display: inline-block;
                    font-size: 18px;
                }
            }
        }
    }
}

/*Page Title*/
.page-title {
    background: #fff;
    padding: 30px 0;
    .entry-title {
        border-color: #121212;
        border-bottom-width: 2px;
        padding-bottom: 10px;
        h1 {
            font-size: 42px !important;
            font-weight: 700 !important;
            line-height: 46px;
            margin-bottom: 10px;
        }
        .date {
            font-size: 13px;
            color: #6c6c6c;
            font-weight: 900;
        }
    }
}


/*Header Current Match*/
.header-current-matches {
    padding-top: 24px;
    padding-bottom: 22px;
    background: #98CAA6;
    .current-matches-item {
        padding: 20px 15px;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
        }
        .date {
            color: #343D43;
            font-size: 14px;
            font-weight: 800;
            line-height: 20px;
            margin-bottom: 0;
        }
        .subtitle {
            color: #343D43;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding-bottom: 8px;
            margin-bottom: 5px;
        }
        .team-wrap {
            .team-name {
                align-items: center;
                display: flex;
                gap: 7px;
                padding: 3px 0;
                img {
                    height: 18px;
                    object-fit: cover;
                    width: 18px;
                }
                p {
                    color: #fff;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: 800;
                }
            }
        }
        .current-matches-footer {
            padding-top: 8px;
            margin-top: 5px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            a {
                display: inline-block;
                font-size: 14px;
                font-weight: 700;
                line-height: 23px;
                margin-right: 7px;
                color: #5E815F;
                &:hover {
                    color: #415e42;
                }
            }
        }
    }
    .current-match-skeleton {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .wrap {
            padding: 10px;
            .react-loading-skeleton {
                --base-color: #76af86;
                --highlight-color: #5fa171;
            }
            .teams {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 10px;
            }
        }
    }
}

.discussion-post-slider {
    background: #272F34;
    padding: 32px 0 42px;
    .section-title {
        .title {
            font-size: 28px;
            line-height: 32px;
            color: #F0F0F0;
            font-weight: 600 !important;
            letter-spacing: 1.5px;
            margin-bottom: 26px;
        }
    }
    .swiper-slide {
        padding: 23px 23px 10px;
        background: #fff;
        border-radius: 15px;
        height: auto;
        .discussion-item {
            .dateTime {
                display: grid;
                grid-template-columns: 1fr auto;
                gap: 10px;
                align-items: center;
                .date {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 800;
                    font-family: var(--heading-font);
                    color: #343D43;
                }
                .live-icon {
                    width: 74px;
                }
            }
            .title {
                font-size: 16px;
                line-height: 22px;
                color: #343D43;
                margin-top: 11px;
                margin-bottom: 6px;
                border-top: 1px solid #E5E5E5;
                border-bottom: 1px solid #E5E5E5;
                padding: 10px 0;
                .badge-status {
                    margin-left: 5px;
                    background: #ffc0c0 !important;
                    padding: 1px 5px;
                    color: #b70000;
                    border-radius: 5px;
                    position: relative;
                    top: 1px;
                }
            }
            .discussion-footer-wrap {
                display: flex;
                justify-content: space-between;
                .excerpt {
                    color: #50A794;
                    font-size: 14px;
                    line-height: 23px;
                }
                .hour-ago {
                    color: #C4C4C4;
                    font-size: 14px;
                    line-height: 23px;
                }
            }
        }
        &:first-child {
            background: #FAF69A;
            .discussion-item {
                .title {
                    border-top: 1px solid rgba(52, 61, 67, 0.1);
                    border-bottom: 1px solid rgba(52, 61, 67, 0.1);
                }
                .excerpt {
                    color: #939056;
                }
                .hour-ago {
                    color: #B1AF6D;
                }
            }
        }
    }
    /*Skeleton*/
    .discussion-skeleton {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 18px;        
        .wrap {
            padding: 30px 23px 10px;
            background: #fff;
            border-radius: 15px;
            &:first-child {
                background: #FAF69A;
                .react-loading-skeleton {
                    --base-color: #ebe795;
                    --highlight-color: #d7d383;
                }
            }
            .discussion-footer-wrap {
                display: flex;
                justify-content: space-between;
            }
        }
    } 
}

/*Fantasy Tips*/ 
.fantasy-tips {
    padding: 30px 0;
    .title-wrap {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border-bottom: 1px solid #F0F0F0;
        padding-bottom: 16px;
        align-items: center;
        margin-bottom: 30px;
        .title {
            font-size: 28px;
            line-height: 33px;
            color: #343D43;            
            margin-bottom: 0;
        }
        .link {
            font-size: 14px;
            line-height: 18px;
            color: #50A794;
        }
    }
    .tips-wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        .tips-item {
            .tips-link {
                img {
                    height: 195px;
                    object-fit: cover;
                    border-radius: 15px;
                    margin-bottom: 12px;
                }
                .title {
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: .7px;
                    color: #343D43;
                    margin-bottom: 20px;
                }
                .date {
                    position: relative;
                    text-align: center;
                    span {
                        background: #fff;
                        padding: 0 16px;
                        position: relative;
                        top: -10px;
                        font-size: 13px;
                        line-height: 15px;
                        color: #C4C4C4;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }
                    &::before {
                        content: '';
                        height: 3px;
                        display: inline-block;
                        background: #F0F0F0;
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                }
                &:hover {
                    .title {
                        color: #50A794;
                    }
                }
            }
        }
    }
    .tips-skeleton {
        .thum {
            border-radius: 15px;
        }
    }
}

/*Fantasy Single*/
.page-single-fantasy-tips {
    .tips-main {
        width: 70%;
        margin: 0 auto;
        padding: 30px 0;
        .featured-image {
            margin-bottom: 20px;
            img {
                border-radius: 15px;
                width: 100%;
            }
        }
        .fusion-imageframe {
            display: none;
        }
        .ez-toc-counter {
            display: none;
        }
        .content-box-wrapper {
            background: #fff;
            box-shadow: 0 0 5px 0px #dde5eb;
            padding: 25px 20px;
            margin-bottom: 20px;
            border-radius: 15px;
        }
    }
}

/*Media Query*/ 
@media only screen and (max-width: 800px) {
    .page-title {
        .entry-title {
            h1 {
                font-size: 24px !important;
                line-height: 24px;
                .badge-status {
                    font-size: 18px;
                }
            }
        }
    }
    .header {
        .main-navigation {
            .nav-wrap {
                grid-template-columns: 100px 1fr auto;
                grid-gap: 10px;
                .nav {
                    .nav-list {
                        .nav-item {
                            a {
                                padding: 12px 3px;
                            }
                        }
                    }
                }
            }
        }
    }
    .current-match-skeleton {
        overflow: hidden;
    }
    .discussion-skeleton {
        overflow: hidden;
    }
    .fantasy-tips {
        .tips-wrap {
            grid-template-columns: 1fr;
            .tips-item {
                .tips-link {
                    img {
                        height: auto;
                    }
                }
            }
        }
    }
    .page-single-fantasy-tips {
        .tips-main {
            width: 100%;
        }
    }
}